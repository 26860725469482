export default [
  { path: "/", redirect: "/Admin/login" },
  // 登录
  {
    path: "/Admin/login",
    name: "admin_login",
    component: () => import("@/views/Admin/login-admin"),
  },

  // 后台界面
  {
    path: "/Admin/index",
    name: "admin_index",
    component: () => import("@/views/Admin/index-admin"),
    children: [
      // 打开默认页面
      {
        path: "/Admin/index",
        name: "admin_default",
        component: () => import("@/views/Admin/default-admin"),
      },

      // 角色管理
      {
        path: "/Admin/roles",
        name: "admin_roles",
        component: () => import("@/views/Admin/roles/index-role"),
      },
      {
        path: "/Admin/roles/form/:id?",
        name: "admin_roles_form",
        component: () => import("@/views/Admin/roles/form-role"),
      },

      // 中奖人员
      {
        path: "/Admin/lottery",
        name: "admin_lottery",
        component: () => import("@/views/Admin/lottery/index-role"),
      },
      {
        path: "/Admin/lottery/form/:id?",
        name: "admin_lottery_form",
        component: () => import("@/views/Admin/lottery/form-role"),
      },

      // 菜单管理
      {
        path: "/Admin/menus",
        name: "admin_menus",
        component: () => import("@/views/Admin/menus/index-menu"),
      },
      {
        path: "/Admin/menus/form/:id?",
        name: "admin_menus_form",
        component: () => import("@/views/Admin/menus/form-menu"),
      },

      // 用户管理
      {
        path: "/Admin/users",
        name: "admin_users",
        component: () => import("@/views/Admin/users/index-user"),
      },

      // 后台管理员
      {
        path: "/Admin/admins",
        name: "admin_admins",
        component: () => import("@/views/Admin/admins/index-admin"),
      },
      {
        path: "/Admin/admins/form/:id?",
        name: "admin_admins_form",
        component: () => import("@/views/Admin/admins/form-admin"),
      },

      // 资金日志
      {
        path: "/Admin/money_logs",
        name: "admin_money_logs",
        component: () => import("@/views/Admin/money_logs/index-logs"),
      },

      // 抽奖记录
      {
        path: "/Admin/raffle_logs",
        name: "admin_raffle_logs",
        component: () => import("@/views/Admin/raffle_logs/index-logs"),
      },

      // 抽奖配置
      {
        path: "/Admin/raffle_set",
        name: "admin_raffle_set",
        component: () => import("@/views/Admin/raffle_set/index-set"),
      },

      // 资金提现
      {
        path: "/Admin/cashes",
        name: "admin_cashes",
        component: () => import("@/views/Admin/cashes/index-cashes"),
      },

      // 配置中心
      {
        path: "/Admin/configs/web",
        name: "admin_configs_web",
        component: () => import("@/views/Admin/configs/web/index-web"),
      }, // 网站配置
      {
        path: "/Admin/configs/upload",
        name: "admin_configs_upload",
        component: () => import("@/views/Admin/configs/oss/index-oss"),
      }, // 上传配置
      {
        path: "/Admin/configs/sms",
        name: "admin_configs_sms",
        component: () => import("@/views/Admin/configs/sms/index-sms"),
      }, // 短信配置
      {
        path: "/Admin/configs/kuaibao",
        name: "admin_configs_kuaibao",
        component: () => import("@/views/Admin/configs/kuaibao/index-kuaibao"),
      }, // 快宝物流查询

      {
        path: "/Admin/sms_logs/index",
        name: "admin_sms_logs_index",
        component: () => import("@/views/Admin/configs/sms/log/index-log"),
      }, // 短信日志
      {
        path: "/Admin/sms_signs/index",
        name: "admin_sms_signs_index",
        component: () => import("@/views/Admin/configs/sms/sign/index-sign"),
      }, // 短信签名列表
      {
        path: "/Admin/sms_signs/form/:id?",
        name: "admin_sms_signs_form",
        component: () => import("@/views/Admin/configs/sms/sign/form-sign"),
      }, // 短信签名编辑

      // 商品管理
      {
        path: "/Admin/goods",
        name: "admin_goods",
        component: () => import("@/views/Admin/goods/index-goods"),
      },
      {
        path: "/Admin/goods/form/:id?",
        name: "admin_goods_form",
        component: () => import("@/views/Admin/goods/form-goods"),
      },

      // 抽奖商品
      {
        path: "/Admin/raffleGoods",
        name: "admin_raffleGoods",
        component: () => import("@/views/Admin/goods_raffle/index-goods"),
      },
      {
        path: "/Admin/raffleGoods/form/:id?",
        name: "admin_raffleGoods_form",
        component: () => import("@/views/Admin/goods_raffle/form-goods"),
      },

      // 商品分类
      {
        path: "/Admin/goods_classes",
        name: "admin_goods_classes",
        component: () => import("@/views/Admin/goods_classes/index-classes"),
      },
      {
        path: "/Admin/goods_classes/form/:id?/:pInd?",
        name: "admin_goods_classes_form",
        component: () => import("@/views/Admin/goods_classes/form-classes"),
      },

      // 商品品牌
      {
        path: "/Admin/goods_brands",
        name: "admin_goods_brands",
        component: () => import("@/views/Admin/goods_brands/index-brands"),
      },
      {
        path: "/Admin/goods_brands/form/:id?",
        name: "admin_goods_brands_form",
        component: () => import("@/views/Admin/goods_brands/form-brands"),
      },

      // 为你找货
      {
        path: "/Admin/seek",
        name: "admin_seek",
        component: () => import("@/views/Admin/seek/index-seek"),
      },

      // 代理中心
      {
        path: "/Admin/agent",
        name: "admin_agent",
        component: () => import("@/views/Admin/agent/index-agent"),
      }, // 代理中心列表
      {
        path: "/Admin/agent/form/:id?",
        name: "admin_agent_form",
        component: () => import("@/views/Admin/agent/form-agent"),
      }, // 代理中心查看
      {
        path: "/Admin/GDPset",
        name: "admin_GDPset",
        component: () => import("@/views/Admin/GDPset/index-agent"),
      }, // 代理中心列表

      // 订单管理
      {
        path: "/Admin/orders",
        name: "admin_orders",
        component: () => import("@/views/Admin/orders/index-order"),
      },
      {
        path: "/Admin/orders/form/:id?",
        name: "admin_orders_form",
        component: () => import("@/views/Admin/orders/form-order"),
      },

      // 订单售后
      {
        path: "/Admin/order_refunds",
        name: "admin_order_refunds",
        component: () => import("@/views/Admin/order_refunds/index-order"),
      },
      {
        path: "/Admin/order_refunds/form/:id?",
        name: "admin_order_refunds_form",
        component: () => import("@/views/Admin/order_refunds/form-order"),
      },

      // 店铺管理
      {
        path: "/Admin/stores",
        name: "admin_stores",
        component: () => import("@/views/Admin/stores/index-store"),
      },
      {
        path: "/Admin/stores/form/:id?",
        name: "admin_stores_form",
        component: () => import("@/views/Admin/stores/form-store"),
      },

      // 分类申请
      {
        path: "/Admin/store_class",
        name: "admin_store_class",
        component: () => import("@/views/Admin/store_class/index-store"),
      },
      {
        path: "/Admin/store_class/form/:id?",
        name: "admin_store_class_form",
        component: () => import("@/views/Admin/store_class/form-store"),
      },

      // 商圈审核
      {
        path: "/Admin/store_examine",
        name: "admin_store_examine",
        component: () => import("@/views/Admin/store_examine/index-store"),
      },
      {
        path: "/Admin/store_examine/form/:id?",
        name: "admin_store_examine_form",
        component: () => import("@/views/Admin/store_examine/form-store"),
      },

      // 本地商圈
      {
        path: "/Admin/store_district",
        name: "admin_store_district",
        component: () =>
          import("@/views/Admin/storeDistrict_classes/index-classes"),
      },
      {
        path: "/Admin/store_district/form/:id?/:pInd?",
        name: "admin_store_district_form",
        component: () =>
          import("@/views/Admin/storeDistrict_classes/form-classes"),
      },

      // 皇晶订单管理
      {
        path: "/Admin/integral_orders",
        name: "admin_integral_orders",
        component: () => import("@/views/Admin/integral_orders/index-integral"),
      },
      {
        path: "/Admin/integral_orders/form/:id?",
        name: "admin_integral_orders_form",
        component: () => import("@/views/Admin/integral_orders/form-integral"),
      },

      // 站点协议
      {
        path: "/Admin/agreements",
        name: "admin_agreements_index",
        component: () => import("@/views/Admin/agreements/index-agreement"),
      }, // 站点协议列表
      {
        path: "/Admin/agreements/form/:id?",
        name: "admin_agreements_form",
        component: () => import("@/views/Admin/agreements/form-agreement"),
      }, // 站点协议编辑

      // 文章 帮助中心
      {
        path: "/Admin/articles",
        name: "admin_articles_index",
        component: () => import("@/views/Admin/articles/index-article"),
      }, // 帮助中心列表
      {
        path: "/Admin/articles/form/:id?",
        name: "admin_articles_form",
        component: () => import("@/views/Admin/articles/form-article"),
      }, // 帮助中心编辑

      // 广告位管理
      {
        path: "/Admin/adv_positions",
        name: "admin_adv_positions",
        component: () => import("@/views/Admin/adv_positions/index-adv"),
      },
      {
        path: "/Admin/adv_positions/form/:id?",
        name: "admin_adv_positions_form",
        component: () => import("@/views/Admin/adv_positions/form-adv"),
      },

      // 广告管理
      {
        path: "/Admin/advs",
        name: "admin_advs",
        component: () => import("@/views/Admin/advs/index-adv"),
      },
      {
        path: "/Admin/advs/form/:id?",
        name: "admin_advs_form",
        component: () => import("@/views/Admin/advs/form-adv"),
      },

      // 数据统计
      {
        path: "/Admin/statistics/user",
        name: "admin_statistics_user",
        component: () => import("@/views/Admin/statistics/user-index"),
      },
      {
        path: "/Admin/statistics/store",
        name: "admin_statistics_store",
        component: () => import("@/views/Admin/statistics/store-index"),
      },
      {
        path: "/Admin/statistics/order",
        name: "admin_statistics_order",
        component: () => import("@/views/Admin/statistics/order-index"),
      },
      {
        path: "/Admin/statistics/pay",
        name: "admin_statistics_pay",
        component: () => import("@/views/Admin/statistics/pay-index"),
      },
    ],
  },
];
