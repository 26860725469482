import { message } from "ant-design-vue";

export function getSession(name) {
  let token_type = sessionStorage.getItem(name);
  return localStorage.getItem(token_type);
}

export function returnInfo(res) {
  if (res.code == 200) {
    return message.success(res.msg);
  } else {
    return message.error(res.msg);
  }
}

export function filterTableMater(arr, code, key = "id", childKey = "children") {
  const queue = [...arr];
  while (queue.length) {
    const el = queue.shift();
    if (el[key] == code) return el;
    queue.push(...(el[childKey] || []));
  }
}
