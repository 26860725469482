import {
  Button,
  Row,
  Col,
  Layout,
  Dropdown,
  Menu,
  Pagination,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Select,
  Switch,
  TimePicker,
  TreeSelect,
  Upload,
  Avatar,
  Badge,
  Descriptions,
  Empty,
  Image,
  Table,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Alert,
  Drawer,
  Modal,
  Progress,
  Result,
  ConfigProvider,
} from "ant-design-vue";

export const antArr = [
  Button,
  Row,
  Col,
  Layout,
  Dropdown,
  Menu,
  Pagination,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Select,
  Switch,
  TimePicker,
  TreeSelect,
  Upload,
  Avatar,
  Badge,
  Descriptions,
  Empty,
  Image,
  Table,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Alert,
  Drawer,
  Modal,
  Progress,
  Result,
  ConfigProvider,
];
