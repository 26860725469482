import { createRouter, createWebHistory } from "vue-router";
import admin from "./admin"; // 后台路由
import seller from "./seller"; // 商家路由

const routes = [
  ...admin,
  ...seller,
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/Error/404-error"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
