import { createApp } from "vue";
import { message, Modal } from "ant-design-vue";
import { antArr } from "./plugins/antD/index";
import IconFont from "./plugins/antD/font";
import "./plugins/css/home.css";
import "./plugins/css/style.css";
import "ant-design-vue/dist/antd.css";
import "dayjs/locale/zh-cn";
import router from "./plugins/router";
import App from "./App.vue";

const app = createApp(App);

app.config.globalProperties.$message = message;
app.config.globalProperties.$modal = Modal;
antArr.forEach((item) => app.use(item));
app.component("IconFont", IconFont);

import { api } from "./plugins/apis/index"; // 请求路径
import {
  get,
  post,
  put,
  deletes,
  postfile,
  isEmpty,
  apiHandle,
} from "./plugins/utils/http"; // 请求方式中间件
app.config.globalProperties.$api = api;
app.config.globalProperties.$post = post;
app.config.globalProperties.$get = get;
app.config.globalProperties.$put = put;
app.config.globalProperties.$delete = deletes;
app.config.globalProperties.$postfile = postfile;
app.config.globalProperties.$isEmpty = isEmpty;
app.config.globalProperties.$apiHandle = apiHandle;

import {
  getSession,
  returnInfo,
  filterTableMater,
} from "./plugins/utils/index"; // 辅助方法
app.config.globalProperties.$getSession = getSession;
app.config.globalProperties.$returnInfo = returnInfo;
app.config.globalProperties.$filterTableMater = filterTableMater;

router.afterEach(() => window.scrollTo(0, 0));
app.use(router);

app.mount("#app");
